<template>
  <div class="user-login login-scale">
    <video
      muted
      autoplay
      loop
      src="/static/video/login.mp4"
      class="video-tvc"
      id="video-tvc"
      object-fit
    >
      抱歉，您的浏览器不支持内嵌视频
    </video>
    <img class="logo" src="/static/loreal-login.png" alt />
    <div class="login-box" style="z-index: 999">
      <dl class="login-box-card">
        <dt class="login-box-header">
          <img class="welcomeImg" src="@/assets/img/WELCOME.png" alt="" />
        </dt>
        <dd class="login-box-body">
          <div class="login-box-row imgTitle">
            <!--            <div class="fisrtImg">Irise</div>-->
            <el-tabs v-model="activeName" stretch @tab-click="handleClick">
              <el-tab-pane label="LION LOGIN" name="SSO"></el-tab-pane>
              <el-tab-pane label="ACCOUNT LOGIN" name="password"></el-tab-pane>
            </el-tabs>
            <!--            <img src="@/assets/img/AttendanceBorder.png" alt="" />-->
          </div>
          <div class="login-container">
            <template v-if="activeName === 'password'">
              <div class="login-box-row" style="position: relative">
                <el-input
                  size="small"
                  :placeholder="AccountPlac"
                  prefix-icon="iconfont icon-oneui-user-thick"
                  v-model="userInfo.username"
                  @blur="inputblur"
                  @input="changeName"
                />
                <span class="errorRemind" v-if="userNameError">{{
                  userNameErrorMsg
                }}</span>
              </div>
              <div class="login-box-row">
                <el-input
                  show-password
                  size="small"
                  :placeholder="passwordPlac"
                  autocomplete="new-password"
                  prefix-icon="iconfont icon-oneui-pass-thick"
                  v-model="userInfo.password"
                  @keyup.enter.native="onLogin"
                />
              </div>
              <div
                class="login-box-row login-box-row2 flexRow"
                style="position: relative"
              >
                <el-input
                  size="small"
                  placeholder="verificationCode"
                  @keyup.enter.native="onLogin"
                  v-model="userInfo.captcha"
                />
                <div class="baseImg">
                  <img :src="baseImg" alt="" />
                </div>
                <span class="seeMore flexRight errorRemind" @click="changePhoto"
                  >看不清楚？<span class="nextOne">换一张</span>
                </span>
              </div>
              <div class="login-box-row sumbit">
                <el-button
                  size="small"
                  type="primary"
                  class="user-login-btn"
                  :loading="loading"
                  @click="onLogin"
                  >LOG IN</el-button
                >
              </div>
            </template>
            <template v-if="activeName === 'SSO'">
              <el-button class="LionButton" type="primary" @click="ssoLogin"
                >LOGIN WITH LION ID</el-button
              >
            </template>
          </div>
        </dd>
      </dl>
    </div>
  </div>
</template>

<script>
import { getPhoto, getSSOApi, getVueBaseApi, getAppName } from '@/api/sys'
const { Message } = $PCommon
export default {
  name: 'UserLogin',

  data() {
    return {
      loading: false,
      userInfo: {
        captcha: '',
        username: '',
        password: ''
      },
      baseImg: '',
      captcha: '',
      AccountPlac: 'Account',
      passwordPlac: 'password',
      activeName: 'SSO',
      userNameError: false,
      userNameErrorMsg: '',
      errPass: 0
    }
  },
  mounted() {
    // console.log('121212121212', this.$router.getRoutes())
  },
  methods: {
    ssoLogin() {
      window.location.href = `${getSSOApi()}?appName=${getAppName()}&callbackUrl=${getVueBaseApi()}/SSOPage`
    },
    handleClick() {
      if (this.activeName === 'password') {
        this.getPhoto()
      }
    },
    onLogin() {
      this.loading = true
      if (this.userNameError) {
        this.loading = false
        return
      }
      if (!this.userInfo.username) {
        Message({
          type: 'error',
          message: '请输入账户名'
        })
        this.loading = false
        return
      }
      if (!this.userInfo.password) {
        Message({
          type: 'error',
          message: '请输入密码'
        })
        this.loading = false
        return
      }
      if (!this.userInfo.captcha) {
        Message({
          type: 'error',
          message: '请输入验证码'
        })
        this.loading = false
        return
      }
      if (this.captcha !== this.userInfo.captcha) {
        const message = '验证码错误，请重新获取'
        Message({
          type: 'error',
          message: message
        })
        this.changePhoto()
        this.loading = false
        return
      }
      const passwordStrongCheck =
        /(?=.*[A-Za-z0-9])(?=.*[A-za-z])(?=.*[0-9])(?=.*[~!@#$%^&*_.]).{8,}/
      if (!passwordStrongCheck.test(this.userInfo.password)) {
        Message({
          type: 'error',
          message: '密码请使用8位及以上，数字、字母、特殊字符'
        })
        this.loading = false
        return
      }
      this.$store
        .dispatch('user/login', {
          username: this.userInfo.username,
          password: this.userInfo.password,
          captcha: this.userInfo.captcha
        })
        .then(() => {
          // 登录后操作
          this.$router.push('/')
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
          console.log(err)
        })
    },
    changePhoto() {
      this.getPhoto()
    },
    inputblur() {
      if (!this.userInfo.username) {
        this.userNameError = true
        this.userNameErrorMsg = '请输入账户名'
      }
    },
    changeName() {
      this.userNameError = false
    },
    async getPhoto() {
      console.log(await getPhoto())
      const { data, code, msg } = await getPhoto()
      if (code === 200) {
        this.baseImg = data
        this.captcha = msg
      }
    }
  }
}
</script>

<style lang="scss">
$text-color: #fff;
.user-login {
  background: #000;
  position: relative;
  width: 100%;
  height: 100%;
  .logo {
    display: block;
    width: 94px;
    position: fixed;
    top: 5%;
    right: 5%;
    z-index: 10;
  }
  .video-tvc {
    height: 100%;
    object-fit: fill;
  }
  .login-box {
    position: fixed;
    top: 47%;
    left: 50%;
    transform: translate(-50%, -50%);

    .login-box-card {
      width: 100%;
      height: 100%;
      background: transparent;
    }
  }
  .login-box-header {
    color: $--color-primary;
    text-align: center;
    font-weight: bold !important;
    margin-bottom: $MP16;
    h1 {
      font-weight: 600;
      margin-bottom: 0;
      color: $text-color;
      font-size: 100px;
    }
    .tab-box {
      width: 300px;
      margin: 0 auto;
      font-size: 28px;
      color: $text-color;
    }
  }
  .login-box-body {
    width: 272px;
    height: 250px;
    margin: 0 auto;
    position: relative;
    text-align: center;
  }
  .flexRow {
    display: flex;
    flex-direction: row;
  }
  .alCenter {
    display: flex;
    align-items: center;
  }
  .errorRemind {
    position: absolute;
    left: 220px;
    font-size: 14px;
    color: #de3934;
    line-height: 38px;
    margin-left: 20px;
    // top:6px;
    white-space: nowrap;
  }
  .baseImg {
    margin-left: 8px !important;
  }

  .baseImg,
  .baseImg img {
    height: 32px;
    width: 126px;
    border-radius: 20px;
    margin-left: 3px;
  }
  .imgTitle img {
    width: 272px;
  }
  .fisrtImg {
    // width: 75px !important;
    width: 272px;
    font-size: 42px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    color: #fff;
  }
  .login-box-row2 {
    .el-input {
      width: 133px;
      height: 32px;
    }
  }
  .seeMore {
    color: #c0bdba;
    cursor: pointer;
  }
  .flexRight {
    display: flex;
    justify-content: flex-start;
  }
  .nextOne {
    color: #ba9765;
  }
  .login-box-row {
    width: 100%;
    align-items: center;
    margin: 0 auto $MP16;
    .el-tabs {
      .el-tabs__item.is-active {
        font-weight: 700;
      }
    }

    .el-input {
      .el-input__inner {
        color: $text-color;
        background: transparent !important;
        border-radius: 16px;
        &:-webkit-autofill {
          box-shadow: 0 0 0px 1000px transparent inset !important;
          text-fill-color: $text-color;
          -webkit-text-fill-color: $text-color;
        }
      }
      // ::placeholder{
      //   color: red;
      // }
      .el-input__icon {
        font-size: 14px;
        margin-left: 5px;
      }
    }
    .el-button {
      border-radius: 16px;
      display: block;
      width: 100%;
      font-size: 14px;
    }
    &.sumbit {
      padding-top: $MP8;
    }
    .el-input__icon {
      color: $text-color;
    }
  }
  .login-box-header-welcome {
    // font-family: "tradeGothicLHExtended" !important;
  }
  .welcomeImg {
    width: 511px;
    height: 77px;
  }
}
.login-container {
  width: 228px;
  margin: 0 auto;
  .LionButton {
    width: 100%;
  }
}
</style>
